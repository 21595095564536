import { AxiosRequestConfig } from 'axios';

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const STATUS_CODE = {
  EMAIL_NOT_EXIST: 'E000032',
  WRONG_PASSWORD: 'E000037',
  WRONG_PASSWORD_LOCKED: 'E000038',
  USER_LOCKED: 'E000039',
  FORGOT_PASS_SEND_OTP_TIMEOUT: 'E000060',
  RESET_PASS_WRONG_OTP: 'E000041',
  WRONG_ORDER_NUMBER: 'E000024',
  WRONG_INPUT_MODAL: 'E000007',
  // estimate price status
  WRONG_DESTINATION_CODE: 'E000007',
  WRONG_FORMAT_SIZES: 'E000012',
  OUT_OF_MACHINING_SIZE_RANGE: 'E000046',
  OUT_OF_MACHINING_RANGE: 'E000047',
  OUT_OF_STOCK: 'E000052',
  WRONG_RADIUS_SIZES: 'E000050',
  ESTIMATE_PRICE_ERROR: 'E000079',
  ESTIMATE_STOCK_ERROR: 'E000048',
  OUT_OF_RANGE_ERROR: 'E000049',
  GET_SIZES_ERROR: 'E000081',
  CURRENCY_TRANSFER: 'W000002',
  OUT_OF_MACHINING_SIZE_DOMESTIC_RANGE: 'E000091',
};

export const STATUS_FIELD = {
  PRODUCT_NAME: 'hakudoShhnm',
  DELIVERY_NAME: 'kokyakum',
};

export const DATE_FORMAT = 'YYYY/MM/DD';
export const SIMPLE_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_HOUR_SECOND = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_HOUR_MIN = 'YYYY/MM/DD HH:mm';
export const HOUR_MIN = 'HH:mm';
export const MONTH_YEAR = 'MM/YYYY';
export const YEAR_MONTH = 'YYYYMM';

const apiVersion = 'v1';
const apiService = 'front';

export const config: AxiosRequestConfig = {
  baseURL:
    `${process.env.REACT_APP_BASE_API_URL}/${apiVersion}/${apiService}` ||
    'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'ec_site_access_token',
  REFRESH_TOKEN: 'ec_site_refresh_token',
  AUTH_ME_INFO: 'ec_site_me_info',
  LANG: 'ec_site_lang',
  SITE_INFO: 'ec_site_info',
};

export const PER = {
  DEFAULT: 10,
  USER: 10,
};

export const fileTypes = ['image/png', 'image/jpeg'];
export const fileSize = 10; //Mb

export enum APP_ACTIONS {
  CHANGE_LANG = 'change_lang',
  UPDATE_MOUNTED = 'update_mounted',
  UPDATE_IS_FETCHING = 'update_is_fetching',
}

export enum CATEGORIES_ID {
  ALUMNINUM = '01',
  COPPER = '02',
  STAINLESS_STEEL = '03',
  STEEL = '04',
  OTHERS = '05',
  PLASTIC = '06',
  TOOL_PRODUCTS = '07',
}

export const CONTACT_INFO = {
  MAIL: 'sjinfb@hakudo.co.jp',
  HOME_PAGE: 'https://www.hakudo.co.jp/',
};

export const REGEX = {
  REGEX_NUMBER: /(.*\d.*)/,
  REGEX_UPPER_CASE: /(.*[A-Z].*)/,
  REGEX_LOWER_CASE: /(.*[a-z].*)/,
  REGEX_SPECIAL_CHARACTER: /(.*\W.*)/,
  REGEX_PASSWORD_MATCHES: /^[a-zA-Z0-9!@#$%^&*()_=;'?{}~<>|[\]\\-`.+,/\\"]*$/,
  REGEX_EMAIL_MATCHES:
    /^[a-zA-Z0-9](([\\.]|[\\+]|[\\-]|[\\_])?[a-zA-Z0-9])+@[a-zA-Z0-9]+([\\.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})$/,
  REGEX_PRODUCT_THICKNESS: /^[0-9x."]*$/,
  REGEX_ONLY_HALFWIDTH_CHARACTERS: /^[a-zA-Z0-9"]*$/,
  REGEX_ONLY_FULLWIDTH_CHARACTERS: /^[ぁ-んァ-ン一-龥ａ-ｚＡ-Ｚ０-９]+$/,
  REGEX_DIMENSIONS: /^(\d+)?x?((\d+)|(\d+)x)(\d+)?$/,
  REGEX_ONLY_NUMBER: /^-?\d*(\.\d*)?$/,
  REGEX_ONLY_INTEGER_NUMBER: /^-?\d*?$/,
  REGEX_EMOJI:
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/,
  REGEX_DIMENSIONS_SIZES1:
    /^\d+(?:\.(?!x)(\d{1,4})?)?(x(?!x)(\d+(?:\.(?!x)(\d{1,4})?)?)?)*$/,
  REGEX_DIMENSIONS_SIZES2_SIZES3: /^\d+(?:\.(\d{1,4})?)?$/,
  REGEX_ALPHABETIC_CHARACTERS: /[a-z]+/i,
  REGEX_NUMERIC_CHARACTER: /[0-9.]/g,
};

export const SIDE_TYPE = {
  TWO_SIDES: 2,
  THREE_SIDES: 3,
  FOUR_SIDES: 4,
  SIX_SIDES: 6,
};

export const SORT = {
  ASC: 'asc',
  DESC: 'desc',
};

export const DISPLAY_OF_TOTAL_AMOUNT = {
  TAX_EXCLUDED: 'TAX_EXCLUDE',
  TAX_INCLUDED: 'TAX_INCLUDE',
};

export const IS_CHECK_POLICY = {
  TRUE: '1',
  FALSE: '0',
};

export const MACHINING_METHOD = {
  EXCLUSION: ['KP', 'FK', 'FP'],
  Z0: 'Z0',
  H2TOH5: ['H2', 'H3', 'H4', 'H5'],
};

export const SHAPE = {
  PLATE: '01',
  BAR: '02',
  PIPE: '03',
};

export const FLAT_PIPE_PRODUCT = {
  KEIJOL1CD: '001',
  KEIJOL2CD: '03',
  KEIJOL3CD: '03',
};

export const SUGGEST_YOUKEN_TYPE = {
  BACK_ORDER: 'BACK_ORDER',
  PRICE_LOW: 'PRICE_LOW',
  FAST_DELIVERY: 'FAST_DELIVERY',
};

export const EMPTYSIZE = '1';

export enum MACHINING_TOLERANCE {
  PLATE_THICKNESS = '板厚',
  WIDTH = '巾',
  LENGTH = '長さ',
  OUTSIDE_DIAMETER = '外径',
  INSIDE_DIAMETER = '内径',
}

export enum FINISHED_PRODUCT_PARAMS {
  DEFAULT_SIZE1 = '1',
  DEFAULT_SNPO_SIZE1 = '1',
  DEFAULT_SETDN_KAKO_CD = '00',
  DEFAULT_SETDN_KAKO = '定尺',
  DEFAULT_FINISHING_SIZE = '1',
  DEFAULT_FINISHING_SIZE_TEXT = '1',
}

export enum SCREEN_ID {
  R1003 = 'R1003',
  R1004 = 'R1004',
}

export const RECOMMEND_SEARCH_KEYWORD = [
  'Hot',
  'Round',
  'Soft',
  'Cold',
  'レコメンド',
];
export enum ROUND {
  TO_2_DIGIT = 2,
  TO_4_DIGIT = 4,
}

export const SAMPLE_PRODUCT_ID = 175311;

export const MACHINES_TO_SORT_SIZE = ['KK'];
