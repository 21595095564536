import dayjs from 'dayjs';
import _ from 'lodash';
import JSEncrypt from 'jsencrypt';
import history from 'src/libs/history';
import qs from 'query-string';

import {
  CATEGORIES_ID,
  DATE_FORMAT,
  MACHINING_METHOD,
  REGEX,
  ROUND,
  SIMPLE_DATE_FORMAT,
} from 'src/constants/app';
import LocalStorage from './LocalStorage';
import { t } from 'src/libs/i18n';
import { ProductType } from 'src/enums/quotation';
import { MACHINING_OPTIONS } from 'src/constants/quotation/optionSelect';

type IsImportedProductOnlyParam = {
  product: string;
  checkZ0: boolean;
  method?: string;
};

type ConvertFinishedSizeType = {
  size: string;
  unitSize: string;
  manufacturingSizes;
  product: string;
  method: string;
  inputValue;
};

export const isValidPassword = (pwd) => {
  const containsNumber = REGEX.REGEX_NUMBER;
  const containsUpperCase = REGEX.REGEX_UPPER_CASE;
  const containsLowerCase = REGEX.REGEX_LOWER_CASE;
  const containsSymbol = REGEX.REGEX_SPECIAL_CHARACTER;

  let matches = 0;

  if (containsNumber.test(pwd)) {
    matches++;
  }

  if (containsUpperCase.test(pwd)) {
    matches++;
  }

  if (containsLowerCase.test(pwd)) {
    matches++;
  }

  if (containsSymbol.test(pwd)) {
    matches++;
  }

  return pwd && matches >= 2;
};

export const mbStrWidth = (input) => {
  let len = 0;
  for (let i = 0; i < input?.length; i++) {
    let code = input.charCodeAt(i);
    if (
      (code >= 0x0020 && code <= 0x1fff) ||
      (code >= 0xff61 && code <= 0xff9f)
    ) {
      len += 1;
    } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
      len += 2;
    } else {
      len += 0;
    }
  }
  return len;
};

export const allowNaNNumber = (currentValue, originalValue) => {
  return originalValue === '' ? null : currentValue;
};

export const formatCountOnUnit = (
  countValue = 0,
  countSuffix = '枚',
  unitValue = 0,
  unitSuffix = 'kg',
  henkanJury = '',
) => {
  const format =
    henkanJury && henkanJury !== ''
      ? `${countValue}${countSuffix} ${henkanJury} (${unitValue}${unitSuffix})`
      : `${countValue}${countSuffix}(${unitValue}${unitSuffix})`;
  return format;
};

export const formatPrice = (value, currency) => {
  return `${value}${currency}`;
};

export const checkExpired = (value: string) => {
  const date = dayjs(value, DATE_FORMAT);
  if (date.isBefore(dayjs().format(DATE_FORMAT))) {
    return true;
  }
  return false;
};

export const optionSelect = (size: 200 | 500 = 200) => {
  const PAGE_SIZE_OPTIONS_200 = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
  ];
  const PAGE_SIZE_OPTIONS_500 = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '300', value: 300 },
    { label: '400', value: 400 },
    { label: '500', value: 500 },
  ];
  if (size === 200) {
    return PAGE_SIZE_OPTIONS_200;
  }
  return PAGE_SIZE_OPTIONS_500;
};

export const validateFormat = (date, format) => {
  return dayjs(date, format).format(format) === date;
};

export const openPDF = (pdf) => {
  window.open(pdf);
};

export const subtractDays = (date, days) => {
  return dayjs(date).subtract(days, 'day').format(SIMPLE_DATE_FORMAT);
};

export const checkOrdered = (item) => {
  if (
    item.kessaiZumiFlg === '1' ||
    (item.chumonUketkeNo !== null && item.chumonUketkeNo !== undefined)
  ) {
    return true;
  }
  return false;
};

export const getTypeQuotation = (item) => {
  if (checkOrdered(item)) {
    return 'ordered';
  } else if (checkExpired(item.mitrYukoKigenb)) {
    return 'expired';
  }
  return 'unexpired';
};

export const displayMultiLanguage = (
  productName,
  defaultName,
  data,
  isHakudoShhnm: boolean,
) => {
  // display product name in selected language
  if (!_.isEmpty(productName)) {
    return productName;
  }
  // english > japanese > first language of the list
  if (!_.isEmpty(isHakudoShhnm ? data?.hakudoShhnmEn : data?.shhnmEn)) {
    return isHakudoShhnm ? data?.hakudoShhnmEn : data?.shhnmEn;
  }
  if (!_.isEmpty(isHakudoShhnm ? data?.hakudoShhnmJp : data?.shhnmJp)) {
    return isHakudoShhnm ? data?.hakudoShhnmJp : data?.shhnmJp;
  }
  let listProductName: string[] = [];
  const listSortLanguage = sortLanguage();

  if (data) {
    for (const key in listSortLanguage) {
      switch (key) {
        case 'vi':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmVn
            : data?.shhnmVn;
          break;
        case 'ko':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmKr
            : data?.shhnmKr;
          break;
        case 'th':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmTh
            : data?.shhnmTh;
          break;
        case 'zh':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmCn
            : data?.shhnmCn;
          break;
        case 'tw':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmTw
            : data?.shhnmTw;
          break;
        default:
          listSortLanguage[key] = '';
          break;
      }
      if (!_.isEmpty(listSortLanguage[key])) {
        listProductName.push(listSortLanguage[key]);
      }
    }
    if (listProductName.length > 0) {
      return _.get(listProductName, '0');
    }
    return defaultName;
  }
};

export const sortLanguage = () => {
  let siteInfo: any = LocalStorage.initSiteInfo;
  let listLanguage: any = [];
  const obj = {};
  siteInfo?.rmGengos?.map((obj) => {
    if (Object.values(obj)[0] !== 'en' && Object.values(obj)[0] !== 'ja') {
      listLanguage.push(Object.values(obj)[0]);
    }
  });
  listLanguage?.forEach((element) => {
    obj[element] = '';
  });
  return obj;
};

export const getProductName = (
  item,
  isHakudoShhnm: boolean,
  defaultName = '',
) => {
  let productName: string = '';
  switch (LocalStorage.lang) {
    case 'en':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmEn : item?.shhnmEn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'ja':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmJp : item?.shhnmJp,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'vi':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmVn : item?.shhnmVn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'ko':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmKr : item?.shhnmKr,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'th':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmTh : item?.shhnmTh,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'zh':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmCn : item?.shhnmCn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'tw':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmTw : item?.shhnmTw,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    default:
      productName = defaultName;
      break;
  }
  return productName;
};

export const formatDimensions = (item, method: string) => {
  // Example: shiagsnpoHyojiMojirets = "15x15x1000(厚さｘ巾ｘ長さ)"
  //          inputSunpo = "1.5inch x 1.5ft x 100inch"
  //          snpoGuide = "厚さｘ巾ｘ長さ"
  //          shiagsnpo = "15x15x1000"
  //          sunpoTaniKubun = "mm"
  if (item?.inputSunpo && item?.inputSunpo !== '' && item?.snpoGuide) {
    // If has convert size, display like this: 1.5inch x 1.5ft x 100inch (厚さｘ巾ｘ長さ) (参考 仕入先寸法15x15x1000mm)
    return `${item?.inputSunpo} (${item?.snpoGuide}) (${t(
      'pricing_page.estimate_results.reference_supplier_dimensions',
    )}${item?.shiagsnpo}${item?.sunpoTaniKubun})`;
  }
  if (item?.snpoGuide) {
    if (
      checkDimensionsWithSpaceBetween(item?.shiagsnpo) &&
      !isMachiningMethodFromH2ToH5(method)
    ) {
      return `${item?.shiagsnpo} (${item?.snpoGuide})`;
    }
    return formatSizeByMachiningMethod(item, method);
  }
  return item?.shiagsnpoHyojiMojirets;
};

export const checkChangeLanguage = (prevLang, lang) => {
  if (prevLang !== lang) {
    return true;
  }
  return false;
};

export const formatManufacturingSizes = (text?: string) => {
  //example: text = 'Outer diameterxWall thickness(Dimension1xDimension2)'
  if (text) {
    //count 'x'
    const count = text?.split('x')?.length - 1;
    if (count > 1) {
      return text?.replaceAll('x', '\nx')?.replaceAll('(', ' \n(');
      //example: text = 'Outer diameter\nxWall thickness\n(Dimension1\nxDimension2)'
    }
    return text?.replaceAll('(', ' (');
  }
};

export const removeZeroAndDuplicateItem = (array?: string[]) => {
  if (array) {
    const newArray = array.filter((item) => item !== '0');
    return _.uniq(newArray).sort((a, b) => parseInt(a) - parseInt(b));
  } else return;
};

export const removeDuplicateObject = (array) => {
  return array.filter((obj, index, arr) => {
    return index === arr.findIndex((o) => o.value === obj.value);
  });
};

export const getSizes = (
  data,
  isSizes1: boolean,
  roundedNumber: number = ROUND.TO_4_DIGIT,
) => {
  const newArray = data?.map((item) => {
    const getSizeValue = () => {
      //Ex: parseFloat(10.12345).toFixed(4) => '10.1235' => Number('10.1235').toString() => '10.1235'
      //    parseFloat(10).toFixed(4) => '10.0000' => Number('10.0000').toString() => '10'
      if (isSizes1) {
        return Number(
          parseFloat(getSizesById(item)).toFixed(roundedNumber),
        ).toString();
      }
      return Number(parseFloat(item).toFixed(roundedNumber)).toString();
    };
    const parts = getSizesById(item)?.split('x');
    if (_.size(parts) > 1) {
      //round to 4th decimal place
      const roundedParts = parts.map(
        (part) => +parseFloat(part).toFixed(roundedNumber),
      );
      return {
        label: roundedParts.join('x'),
        value: roundedParts.join('x'),
        key: item,
      };
    } else {
      return {
        label: getSizeValue(),
        value: getSizeValue(),
        key: item,
      };
    }
  });
  return newArray;
};

function sortSizesByValueWithDelimiter(sizes: { value: string }[]) {
  const splitSizes = sizes.map(
    (item) => item?.value?.split('x').map((v) => parseFloat(v)) || [],
  );

  const sizesByIndex = sizes.map((size, idx) => {
    return { size, idx };
  });

  sizesByIndex.sort((it1, it2) => {
    const arr1 = splitSizes[it1.idx];
    const arr2 = splitSizes[it2.idx];

    for (let i = 0; i < Math.min(arr1.length, arr2.length); i++) {
      if (arr1[i] < arr2[i]) {
        return -1;
      } else if (arr1[i] > arr2[i]) {
        return 1;
      }
    }
    return arr1.length - arr2.length;
  });
  return sizesByIndex.map((each) => each.size);
}

export const sortSizesByValue = (sizes: { value: string }[]) => {
  // Some values in format: [{value: "10x20x30"}, {value: "10"}]
  const hasSizeValueWithDelimiter = _.some(sizes, (item) =>
    item.value.includes('x'),
  );

  if (hasSizeValueWithDelimiter) {
    return sortSizesByValueWithDelimiter(sizes);
  } else {
    return _.sortBy(sizes, (it) => parseFloat(it.value));
  }
};

export const getSizesById = (item) => {
  //item:  shhnCd-hakudoShhnCd-api1ShhnCd-api2ShhnCd-id
  //get code by id
  return (item = item?.split('-')[getIdOfSize(item)]);
};

export const getIdOfSize = (item) => {
  //item:  shhnCd-hakudoShhnCd-api1ShhnCd-api2ShhnCd-id
  return +item?.split('-')[4];
};

export const checkHasDomesticData = (data) => {
  //item:  shhnCd-hakudoShhnCd-api1ShhnCd-api2ShhnCd-id
  return data?.some((item) => getIdOfSize(item?.key) === 0);
};

export const isDomesticProduct = (product) => {
  // id of domestic product is "null-....."
  return product?.split('-')[1] !== 'null';
};

export const isImportedProduct = (product) => {
  // id of imported product is "....-null"
  return product?.split('-')[0] !== 'null';
};

export const getDeliveryDateExternal = (text) => {
  //text example: 船便：30～45日以内\n航空機便：7~11日以内
  if (text) {
    const parts = text.split('\n');

    //Split each element into subvalues
    const byShipDate = parts[0]?.split('：')[1]?.split('日以内')[0];
    const byAirplaneDate = parts[1]?.split('：')[1]?.split('日以内')[0];

    const obj = {
      byShip: byShipDate,
      byAirplane: byAirplaneDate,
    };
    return obj;
  }
};

export const convertFinishedSize = (param: ConvertFinishedSizeType) => {
  // Example:
  // ThicknessxSide(Dimension1xDimension2)inchxfit     Length(Dimension3)fit
  //                0.0394x0.0492                   x          13.12            => 0.0394inch x 0.0492fit x 13.12fit
  //                0.0394x0.0492                   x                           => 0.0394inch x 0.0492fit x 1
  // ThicknessxSide(Dimension1xDimension2)inchxfit     Length(Dimension3)
  //                0.0394x0.0492                   x          13.12            => 0.0394inch x 0.0492fit x 13.12
  const { inputValue, manufacturingSizes, method, product, size, unitSize } =
    param;
  const sizeArray = size?.split('x');
  const unitSizeArray = unitSize?.split('x');
  const isImportedProductOnlyAndZ0 = isImportedProductOnly({
    product: product,
    checkZ0: true,
    method: method,
  });
  const isWidthOrLengthExist =
    (manufacturingSizes?.size1Label && inputValue?.product_width) ||
    (manufacturingSizes?.size2Label && inputValue?.product_length);

  const newArray = sizeArray.map((value, index) => {
    // if unitSize === 'empty' or unitSize === originSize => not show unitSize
    const shouldAppendUnitSizeArray =
      unitSizeArray[index] !== 'empty' &&
      unitSizeArray[index] !== manufacturingSizes?.originSize;

    if (isImportedProductOnlyAndZ0) {
      //if index < snpoSize
      const shouldAppendUnitSizeArrayForSnpo =
        index < manufacturingSizes?.snpoSize;
      // else if isWidthOrLengthExist
      const shouldAppendUnitSizeArrayForWidthOrLength =
        isWidthOrLengthExist && !shouldAppendUnitSizeArrayForSnpo;

      const shouldAppendUnitSizeArrayForImportedProduct =
        shouldAppendUnitSizeArray &&
        (shouldAppendUnitSizeArrayForSnpo ||
          shouldAppendUnitSizeArrayForWidthOrLength);

      return shouldAppendUnitSizeArrayForImportedProduct
        ? `${value}${unitSizeArray[index]}`
        : `${value}`;
    }

    return shouldAppendUnitSizeArray
      ? `${value}${unitSizeArray[index]}`
      : `${value}`;
  });

  if (checkAnyElementHasUnit(newArray)) {
    return newArray.join(' x ');
  }
  return newArray.join('x');
};

export const renderSize = (item, method: string) => {
  if (item?.inputSunpo && item?.inputSunpo !== '') {
    // Example: shiagsnpoHyojiMojirets = "15x15x1000(厚さｘ巾ｘ長さ)" (ImportedProduct : 15x15x1000 | DomesticProduct: 15mmx15mmx1000mm)
    // Example: inputSunpo = "1.5inchx1.5ftx100inch"
    const shiagsnpoHyojiMojiretsArray =
      item?.shiagsnpoHyojiMojirets?.split('(');
    const dimension = shiagsnpoHyojiMojiretsArray?.[0]; // Example: 15x15x1000
    const dimensionLabel = shiagsnpoHyojiMojiretsArray?.[1]; // Example: 厚さｘ巾ｘ長さ)
    // If has convert size, display like this: 1.5inchx1.5ftx100inch (厚さｘ巾ｘ長さ) (参考 仕入先寸法15x15x1000mm)
    return `${item?.inputSunpo} (${dimensionLabel} (${t(
      'pricing_page.estimate_results.reference_supplier_dimensions',
    )}${dimension}${item?.sunpoTaniKubun})`;
  } else {
    // Else: 15x15x1000(厚さｘ巾ｘ長さ)
    return formatSizeByMachiningMethod(item, method);
  }
};

export const renderWeight = (henkanJury = '', jury = '') => {
  if (henkanJury && henkanJury !== '' && jury && jury !== '') {
    return `${henkanJury} (${jury})`;
  } else {
    return jury;
  }
};

export const isImportedProductOnly = (param: IsImportedProductOnlyParam) => {
  const { checkZ0, product, method } = param;
  const machiningMethodOfZ0AndH2ToH5 = [
    MACHINING_METHOD.Z0,
    ...MACHINING_METHOD.H2TOH5,
  ];
  if (checkZ0 && method) {
    return (
      isImportedProduct(product) &&
      !isDomesticProduct(product) &&
      machiningMethodOfZ0AndH2ToH5.includes(method)
    );
  }
  return isImportedProduct(product) && !isDomesticProduct(product);
};

export const convertDimensionsToArray = (dimensions) => {
  if (checkDimensionsWithSpaceBetween(dimensions)) {
    //example:13mm x 2000mm
    return dimensions?.split(' x ')?.map((item) => parseFloat(item).toString());
  }
  //example:13x2000
  return dimensions?.split('x')?.map((item) => parseFloat(item).toString());
};

export const convertShiagsnpoHyojiMojiretsToArray = (
  shiagsnpoHyojiMojirets,
) => {
  const dimensions = shiagsnpoHyojiMojirets?.split('(')[0]?.split('x');
  if (checkDimensionsWithSpaceBetween(shiagsnpoHyojiMojirets)) {
    // Example: shiagsnpoHyojiMojirets = "15mmx15mmx1000mm(厚さｘ巾ｘ長さ)" => [15mm,15mm,1000mm]
    return dimensions;
  }
  // Example: shiagsnpoHyojiMojirets = "15mmx15mmx1000mm(厚さｘ巾ｘ長さ)" => [15,15,1000]
  return dimensions?.map((item) => parseFloat(item).toString());
};

export const formatSizeByMachiningMethod = (item, method) => {
  // Split the snpo guide to get the length dimension position
  // Then round the length dimension to 2 decimal places
  // Example:
  // 1. shiagsnpoHyojiMojirets = "12x1333.333(直径x長さ)" => [12,1333.333]
  // Index of Length: 1, value: 1333.333, round to 2 decimal places -> 1333.33
  // 2. shiagsnpoHyojiMojirets = "12x15x15x1000x3.333(厚さx孔径xピッチx巾x長さ)" => [12x15x15,1000,3.333]
  // Index of Length: 2, value: 3.333, round to 2 decimal places -> 3.33

  // If product has maching method from H2 to H5 then split the dimensions and round the length.
  if (item?.shiagsnpo && isMachiningMethodFromH2ToH5(method)) {
    //If product has unit type (mm, cm, inch,...)
    const separator = checkDimensionsWithSpaceBetween(item?.shiagsnpo)
      ? ' x '
      : 'x';
    // item.shiagsnpo = 12mm x 1333.333mm
    return `${convertShiagsnpoHyojiMojiretsToArray(item.shiagsnpo)
      // arr = [12mm, 1333.333mm]
      ?.map((dimension, index, arr) =>
        // Get last element = 1333.333mm
        arr.length > 1 && index === arr.length - 1
          ? // Round to 2 decimal places
            // arr[1] = 1333.33
            roundDecimalPlaces(parseFloat(dimension), 2) +
            // unit = mm
            dimension.replace(REGEX.REGEX_NUMERIC_CHARACTER, '').trim()
          : dimension,
      )
      // '12mm' + ' x ' + '1333.33' + 'mm' + ' ' + '(直径x長さ)'
      .join(separator)}${separator === ' x ' ? ' ' : ''}(${item.snpoGuide})`;
  }
  // If not, return original snpo guide
  return `${item?.shiagsnpo}(${item?.snpoGuide})`;
};

export const renderNoukiDisplay = (noukiDisplay) => {
  if (checkNoukiDisplay(noukiDisplay)) {
    return t('pricing_page.estimate_results.delivery_date_external', {
      0: getDeliveryDateExternal(noukiDisplay)?.byShip,
      1: getDeliveryDateExternal(noukiDisplay)?.byAirplane,
    });
  } else {
    return noukiDisplay;
  }
};

export const checkDimensionsWithSpaceBetween = (dimensions) => {
  return dimensions?.split(' x ')?.length > 1;
};

export const checkAnyElementHasUnit = (arr) => {
  const hasUnit = arr.some((element) => {
    const unit = element.match(REGEX.REGEX_ALPHABETIC_CHARACTERS); // Search for unit in the element
    if (unit !== null && unit.length > 0) {
      // If a unit is found
      return true;
    } else {
      return false;
    }
  });
  return hasUnit;
};

export const encryptData = (data: string) => {
  if (process.env.REACT_APP_PUBLIC_KEY) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    return encrypt.encrypt(data) || '';
  }
  return '';
};

export const isMachiningMethodFromH2ToH5 = (method: string) => {
  return MACHINING_METHOD.H2TOH5.includes(method);
};

export const roundDecimalPlaces = (size, decimalPlaces: number) => {
  return Number(parseFloat(size).toFixed(decimalPlaces));
};

export const roundSizeForMachiningMethodFromH2ToH5 = (
  size: string | undefined,
  method: string,
) => {
  return size && isMachiningMethodFromH2ToH5(method)
    ? Number(parseFloat(size).toFixed(2)).toString()
    : size;
};

export const getColumnNameMulti = (item, defaultName, colKey) => {
  let columnName: string = '';
  const colNumber = Number(colKey?.substring(1));
  switch (LocalStorage.lang) {
    case 'en':
      columnName = _.isEmpty(item?.colmEn)
        ? `${defaultName + colNumber}`
        : item?.colmEn;
      break;
    case 'ja':
      columnName = _.isEmpty(item?.colmJp)
        ? `${defaultName + colNumber}`
        : item?.colmJp;
      break;
    case 'vi':
      columnName = _.isEmpty(item?.colmVn)
        ? `${defaultName + colNumber}`
        : item?.colmVn;
      break;
    case 'ko':
      columnName = _.isEmpty(item?.colmKr)
        ? `${defaultName + colNumber}`
        : item?.colmKr;
      break;
    case 'th':
      columnName = _.isEmpty(item?.colmTh)
        ? `${defaultName + colNumber}`
        : item?.colmTh;
      break;
    case 'zh':
      columnName = _.isEmpty(item?.colmCn)
        ? `${defaultName + colNumber}`
        : item?.colmCn;
      break;
    case 'tw':
      columnName = _.isEmpty(item?.colmTw)
        ? `${defaultName + colNumber}`
        : item?.colmTw;
      break;
    default:
      columnName = `${defaultName + colNumber}`;
      break;
  }
  return columnName;
};

export const createFileFromString = (data: string, fileName: string) => {
  const downloadUrl = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const checkAllPropertiesNull = (obj) => {
  return Object.values(obj).every((value) => value === null);
};

export const checkIsProductFinished = (data) => {
  return data === CATEGORIES_ID.TOOL_PRODUCTS;
};

export const isAllTrue = (obj) => {
  return Object.values(obj).every((value) => value === true);
};

export const handleRedirectProductDetail = (product, hinshL3Cd, pathname) => {
  const queryParams: { [key: string]: string } = {};
  const propertiesToCheck = [
    'shhnCd',
    'hakudoShhnCd',
    'api1ShhnCd',
    'api2ShhnCd',
  ];

  propertiesToCheck.forEach((property) => {
    if (product?.[property]) {
      queryParams[property] = product[property];
    }
  });
  queryParams.hinshL3Cd = hinshL3Cd;
  history.push({
    pathname: pathname,
    search: qs.stringify(queryParams),
  });
};

export const findOriginSize = (arrayData, value) => {
  const sizesData = arrayData?.find((item) => item.value === value)?.key;
  return sizesData;
};

export const checkNoukiDisplay = (noukiDisplay) => {
  //noukiDisplay: "船便：30～45日以内\n航空機便：7~11日以内" OR "2024-04-25 10:53:37"
  return noukiDisplay?.includes('日以内');
};

export const checkBackOrderProductAndNoukiDisplay = (item) => {
  return (
    item?.zaitokKbn === ProductType.BACK_ORDER_PRODUCT &&
    checkNoukiDisplay(item?.noukiDisplay)
  );
};

export const renderToleranceOptions = (list) => {
  const listOptions = list?.map((item) => {
    return {
      label: item?.kakokosaTanka,
      value: item?.kakokosaCd,
    };
  });
  return listOptions;
};

export const checkMachiningOptions = (product, toleranceOptions, apiCalled) => {
  if (isDomesticProduct(product)) {
    return MACHINING_OPTIONS;
  }
  if (apiCalled) {
    if (toleranceOptions) {
      const defaultValue = { label: '', value: '' };
      if (!_.includes(toleranceOptions, defaultValue)) {
        toleranceOptions = _.concat([defaultValue], toleranceOptions);
      }
    }
    return toleranceOptions || MACHINING_OPTIONS;
  }
  return [];
};
